@import "variables";

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  background-color: $off-white;
}
.clickable {
  cursor: pointer;
}
.flex {
  -ms-flex: 1; // ie 10
  flex: 1
}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  margin-top: 10px;
}
.logo-login {
  margin-bottom: 2*$spacer;
  width: 480px;
  height: 120px;


  @include media-breakpoint-up(md) {
    width: 480px;
    height: 120px

  }
  @include media-breakpoint-up(lg) {
    width: 480px;
    height: 120px;
  }

}
.MuiTabs-indicator {
  background-color: rgba(0,0,0,0.4) !important;
}
.MuiTab-root{
  font-family: $label-font-family !important;
  color: $white !important;
  width: 25%;
  max-width: 400px !important;

  @include media-breakpoint-down(sm) {
    font-size: 12px !important;
  }

}
.MuiAppBar-colorPrimary{
  background-color: $brand-primary-1 !important;
  color: $white !important;
}
.accordion-sm{
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }
}
.MuiAccordion-root {
  margin: 10px 0 !important;
  width: 100%;
}
.MuiAccordionSummary-content{
  font-size: 15px;
  font-weight: 500;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}
.MuiAccordionSummary-root.Mui-expanded{
  background-color: #737373;
  border-radius: 4px 4px 0px 0px !important;
  border-bottom: 3px solid $brand-primary-2;
  color: $white;
}
.MuiAccordionSummary-expandIcon.Mui-expanded{
  color: $white;
}
.h-100 {
  height: 100%;
}
.mw-480 {
  max-width: 480px;
}
.mw-560 {
  max-width: 560px;
}
.mw-640 {
  max-width: 640px;
}
.w-640 {
  width: 640px;
}
.w100 {
  width: 100%;
}
.MuiPaginationItem-outlined{
  background-color: $white !important;
}
.MuiPaginationItem-page.Mui-selected{
  background-color: rgba(0, 0, 0, 0.08) !important;
}
.body {
  padding: $spacer;

  @include media-breakpoint-up(md) {
	padding: 2 * $spacer;
  }
}
.full-max-width {
  max-width: calc(100vw - 4*#{$back-button-size});
}
.body-with-filter {
  margin-bottom: $spacer;
}
.spacer {
  margin-top: $spacing-between-rows;
}
.double-spacer {
  margin-top: 2 * $spacing-between-rows;
}
.spacer-bottom {
  margin-bottom: $spacing-between-rows;
}
.full-height {
  height: 100%;
}
.color-brand {
  color: $brand-primary-1 !important;
}
.color-brand-2 {
  color: $brand-primary-2 !important;
}
.color-secondary {
  color: $brand-secondary-1 !important;
}
.color-pure-red {
  color: $pure-red !important;
}
.color-red {
  color: $dark-red !important;
}
.color-coral {
  color: $coral !important;
}
.color-yellow {
  color: $progress-bar-target-color !important;
}
.color-success {
  color: $dark-green !important;
}
.color-white {
  color: $white !important;
}
.color-dark {
  color: $gray-600 !important;
}
.color-black {
  color: $black;
}
.color-gold {
  color: $gold !important;
}
.bg-brand {
  background-color: $brand-secondary-1 !important;
}
.bg-pure-red {
  background-color: $pure-red !important;
}
.bg-dark-red {
  background-color: $dark-red !important;
}
.bg-red {
  background-color: $red !important;
}
.bg-white {
  background-color: $white !important;
}
.bg-dark {
  background-color: $gray-600 !important;
}
.bg-transparent {
  background-color: transparent !important;
}

.label {
  font-family: $label-font-family;
  font-weight: $label-font-weight;
  font-size: $label-font-size;
  color: $label-font-color;
  line-height: $label-line-height;
  letter-spacing: $label-letter-spacing;

  @include media-breakpoint-up(xl) {
    font-size: $label-font-size;
  }
}
.user-table{
  font-size: 9px !important;

  @include media-breakpoint-up(md) {
    font-size: 12px !important;
  }
}
.data-label {
  font-family: $data-label-font-family;
  font-weight: $data-label-font-weight;
  font-size: $lg-data-label-font-multiplier * $data-label-font-size;
  color: $data-label-font-color;
  line-height: $data-label-line-height;
  text-transform: uppercase;

  @include media-breakpoint-up(xl) {
    font-size: $data-label-font-size;
  }
}

.label-simulacao-parcela {
  font-size: 40px;
}

.botao-simulacao {
  margin-top: 18px;
}
.fix-ie-width {
  max-width: 99.9999%;
}
@each $breakpoint, $container-max-width in $container-max-widths {
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    .stretch-left {
      margin-left: calc((100vw - #{$container-max-width}) / -2);
    }
    .stretch-left-with-gutter {
      margin-left: calc((100vw - #{$container-max-width} - #{$grid-gutter-width} ) / -2);
    }
  }
}
@each $breakpoint, $container-max-width in $container-max-widths {
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    .stretch-right {
      margin-right: calc((100vw - #{$container-max-width}) / -2);
    }
    .stretch-right-with-gutter {
      margin-right: calc((100vw - #{$container-max-width} - #{$grid-gutter-width}) / -2);
    }
  }
}
.stretch-height {
  height: calc(100vh - 34px - (#{$spacing-between-rows}*3) - #{$header-height}) !important;
}
.fix-center {
  margin-top: -104px;
}

.icon-small {
  font-size: $icon-small-font-size !important;
}

.h-32 {
  height: 32px;
}
.h-90 {
  height: 90px;
}

.message-dialog {
  font-family: $font-family-base;
  font-weight: 500;
  font-size: 16px;
}
.message-dialog-success {
  background-color: $dark-green !important;
}
.message-dialog-error {
  background-color: $dark-red !important;
}
.message-dialog-warning {
  background-color: $dark-yellow !important;
}
.message-dialog-info {
  background-color: $dark-blue !important;
}
.message-dialog-icon {
  margin-right: 10px;
}
.message-dialog-text {
  white-space: pre-wrap;
  font-family: $font-family-base;
  font-weight: 300;
  font-size: 14px;
}
.capitalize-first-letter:first-letter {
  text-transform: capitalize;
  color: white;
}
.card-welcome-page {
	font-weight: 500;
}

.input-loader {
  height: 3px !important;
}

.cssload-loader {
  border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  perspective: 1500px;
}
.cssload-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}
.cssload-inner.cssload-one-brand {
  left: 0%;
  top: 0%;
  animation: cssload-rotate-one 2s linear infinite;
  -o-animation: cssload-rotate-one 2s linear infinite;
  -ms-animation: cssload-rotate-one 2s linear infinite;
  -webkit-animation: cssload-rotate-one 2s linear infinite;
  -moz-animation: cssload-rotate-one 2s linear infinite;
  border-bottom: 10px solid $pink;
}
.cssload-inner.cssload-two-brand {
  right: 0%;
  top: 0%;
  animation: cssload-rotate-two 2s linear infinite;
  -o-animation: cssload-rotate-two 2s linear infinite;
  -ms-animation: cssload-rotate-two 2s linear infinite;
  -webkit-animation: cssload-rotate-two 2s linear infinite;
  -moz-animation: cssload-rotate-two 2s linear infinite;
  border-right: 10px solid $dark-red;
}
.cssload-inner.cssload-three-brand {
  right: 0%;
  bottom: 0%;
  animation: cssload-rotate-three 2s linear infinite;
  -o-animation: cssload-rotate-three 2s linear infinite;
  -ms-animation: cssload-rotate-three 2s linear infinite;
  -webkit-animation: cssload-rotate-three 2s linear infinite;
  -moz-animation: cssload-rotate-three 2s linear infinite;
  border-top: 10px solid $coral;
}

.cssload-inner.cssload-one-white {
  left: 0%;
  top: 0%;
  animation: cssload-rotate-one 2s linear infinite;
  -o-animation: cssload-rotate-one 2s linear infinite;
  -ms-animation: cssload-rotate-one 2s linear infinite;
  -webkit-animation: cssload-rotate-one 2s linear infinite;
  -moz-animation: cssload-rotate-one 2s linear infinite;
  border-bottom: 10px solid white;
}
.cssload-inner.cssload-two-white {
  right: 0%;
  top: 0%;
  animation: cssload-rotate-two 2s linear infinite;
  -o-animation: cssload-rotate-two 2s linear infinite;
  -ms-animation: cssload-rotate-two 2s linear infinite;
  -webkit-animation: cssload-rotate-two 2s linear infinite;
  -moz-animation: cssload-rotate-two 2s linear infinite;
  border-right: 10px solid $white;
}
.cssload-inner.cssload-three-white {
  right: 0%;
  bottom: 0%;
  animation: cssload-rotate-three 2s linear infinite;
  -o-animation: cssload-rotate-three 2s linear infinite;
  -ms-animation: cssload-rotate-three 2s linear infinite;
  -webkit-animation: cssload-rotate-three 2s linear infinite;
  -moz-animation: cssload-rotate-three 2s linear infinite;
  border-top: 10px solid $white;
}
.z-index-1 {
  z-index: 1 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}
.restrict {
  overflow: hidden;
}
.d-none {
  display: none;
}

@keyframes cssload-rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}
@-o-keyframes cssload-rotate-one {
  0% {
    -o-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    -o-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}
@-ms-keyframes cssload-rotate-one {
  0% {
    -ms-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    -ms-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}
@-webkit-keyframes cssload-rotate-one {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}
@-moz-keyframes cssload-rotate-one {
  0% {
    -moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    -moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes cssload-rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}
@-o-keyframes cssload-rotate-two {
  0% {
    -o-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    -o-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}
@-ms-keyframes cssload-rotate-two {
  0% {
    -ms-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    -ms-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}
@-webkit-keyframes cssload-rotate-two {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}
@-moz-keyframes cssload-rotate-two {
  0% {
    -moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    -moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes cssload-rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
@-o-keyframes cssload-rotate-three {
  0% {
    -o-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    -o-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
@-ms-keyframes cssload-rotate-three {
  0% {
    -ms-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    -ms-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
@-webkit-keyframes cssload-rotate-three {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
@-moz-keyframes cssload-rotate-three {
  0% {
    -moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    -moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

.img-error {
  width: 320px;
  border-radius: 5px;

  @include media-breakpoint-up(md) {
	width: 480px;
  }
  @include media-breakpoint-up(lg) {
	width: 640px;
  }
}
.text-transform-unset {
  text-transform: unset !important;
}
