$white: #fff !default;
$black: #000 !default;
$almost-black: #3a3f53 !default;
$off-white: #f6f6f6 !default;
$dark-gray: #5d647c !default;
$transparent-almost-black: #3a3f53cc !default;

$dark-green: #108d32 !default;
$pure-green: #00ff00 !default;
$mild-green: #0dba3c !default;
$pink: #b41a83 !default;
$purple: #702F8A !default;
$dark-pink: #89355f !default;
$dark-purple: #710c89 !default;
$dark-red: #990a26 !default;
$pure-red: #ff0000 !default;
$other-red: #EE0823 !default;
$coral: #FF3B51 !default;
$pure-blue: #0000ff !default;
$dark-blue: #005298 !default;
$mild-blue: #2f5290 !default;
$really-light-blue: #f5faff !default;
$pure-yellow: #ffff00 !default;
$mild-yellow: #e5dd23 !default;
$dark-yellow: #ce9804 !default;
$light-yellow: #f1efce !default;
$disabled-gray: #8b8b8b !default;
$menu-red: #D6344E !default;
$dark-orange: #f4592f !default;
$mild-orange: #FF914D !default;
$orange: #F39200 !default;

$gold: #bf9c43 !default;
$light-gold: #d2ccc0 !default;

$separator: #e9ecef;
$light-gray: #b6bcd1;

$brand-primary-1: #004891;
$brand-primary-2: #020550;

$brand-primary-gradient: linear-gradient(45deg, $brand-primary-2 0%, $brand-primary-1 100%);
$brand-alternative-primary-gradient: linear-gradient(135deg, $other-red 0%, $dark-pink 49%, $mild-blue 100%);

$button-gradient: linear-gradient(135deg, $pink 0%, $dark-red 49%, $coral 100%);

$brand-secondary-1: $dark-blue;
$brand-secondary-2: $mild-blue;

$brand-secondary-gradient: linear-gradient(45deg, $brand-secondary-1 0%, $brand-secondary-2 100%);
$brand-secondary-gradient-back: linear-gradient(90deg, $brand-secondary-1 30%, $brand-secondary-2 100%);
$brand-secondary-gradient-lateral-panel: linear-gradient(135deg, $brand-secondary-1 0%, $brand-secondary-2 100%);

$success: $dark-green;
$failure: $dark-red;

$contratadas-mes-anterior-1: #ca5ad8 !default;
$contratadas-mes-anterior-2: #622c69 !default;

$contratadas-mes-anterior-gradient: linear-gradient(45deg, $contratadas-mes-anterior-1, $contratadas-mes-anterior-2);

$contratadas-mes-atual-1: #2d80c7 !default;
$contratadas-mes-atual-2: #1c3a53 !default;

$contratadas-mes-atual-gradient: linear-gradient(45deg, $contratadas-mes-atual-1, $contratadas-mes-atual-2);

$em-contratacao-1: #c73b65 !default;
$em-contratacao-2: #531011 !default;

$em-contratacao-gradient: linear-gradient(45deg, $em-contratacao-1, $em-contratacao-2);

$credito-aprovado-1: #4f9061 !default;
$credito-aprovado-2: #1a5822 !default;

$credito-aprovado-gradient: linear-gradient(45deg, $credito-aprovado-1, $credito-aprovado-2);

$card-proposta-title: $purple !default;
